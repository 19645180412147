import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={'h5'}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              '&::before': {
                display: 'none',
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = () => {
  const faqData = [
    {
      title: 'Frequently Asked Questions',
      items: [
        {
          title: 'What is Provex?',
          subtitle:
            'Provex is a leading provider of identity verification solutions. We offer a range of tools and APIs that enable businesses and individuals to verify the identities of their users in real-time.',
        },
        {
          title: 'What identity data can Provex verify?',
          subtitle:
            'Provex provides real-time access to essential identity data, including National Identification Numbers (NIN), Bank Verification Numbers (BVN), Tax Identification Numbers (TIN), Corporate Affairs Commission (CAC) records, International Passport information, and more.',
        },
        {
          title: 'How can Provex benefit my business?',
          subtitle:
            "Provex's identity verification solutions can streamline your onboarding process, enhance security measures, and ensure compliance. Our services are designed to meet the needs of startups and established enterprises alike.",
        },
        {
          title: 'Is Provex suitable for financial institutions?',
          subtitle:
            'Yes, Provex\'s BVN and NIN verification services are ideal for financial institutions looking to improve customer due diligence processes and strengthen security measures.',
        },
        {
          title: 'Can I integrate Provex with my existing systems?',
          subtitle:
            'Absolutely. Provex offers seamless integration options, allowing you to easily incorporate our identity verification services into your current systems.',
        },
        {
          title: 'How does Provex prevent fraudulent activities?',
          subtitle:
            'Provex\'s services, such as International Passport information integration, play a crucial role in reducing fraudulent activities and ensuring a more secure experience for your users.',
        },
        {
          title: 'Is Provex compliant with industry regulations?',
          subtitle:
            'Yes, Provex\'s suite of identity verification solutions ensures compliance with industry regulations, making it an ideal partner for businesses in heavily regulated sectors.',
        },
        {
          title: 'Can Provex help speed up identity verification processes?',
          subtitle:
            'Provex is all about speed and efficiency. Our TIN and CAC records integration, for instance, helps deliver quick results, making identity verification a seamless process.',
        },
        {
          title: 'What sets Provex apart from other identity verification providers?',
          subtitle:
            'Provex\'s commitment to revolutionizing the verification process, its focus on security, and the real-time access to essential identity data make it a standout choice for businesses and individuals.',
        },
        {
          title: 'How can I get started with Provex?',
          subtitle:
            "To get started with Provex's identity verification solutions, simply reach out to our team, and we'll guide you through the integration process and discuss how we can tailor our services to your specific needs.",
        },
        // Add more questions and answers as needed
      ],
    },
  ];

  return (
    <Box>
      {faqData.map((faqGroup, index) => (
        <Box marginBottom={6} key={index}>
          <FaqGroupItem title={faqGroup.title} items={faqGroup.items} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
