import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: '1. What information do we collect?',
    description:
    'We collect information that you provide, as well as data generated during your use of our services.'
  },
  {
    title: '2. How do we use your information?',
    description:
    'We use your information to provide and improve our services, customize services for you, better understand our users, provide feedback to third party business partners, and to help our users connect with third party business partners.'
  },
  {
    title: '3. Will your information be shared with anyone?',
    description:
    'We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.'
  },
  {
    title: '4. Do we use cookies or other tracking technologies?',
    description:
    'We use cookies and other tracking technologies to collect and store your information.'
  },
  {
    title: '5. Is your information transferred internationally?',
    description:
    'We may transfer, store, and process your information in countries other than your own.'
  },
  {
    title: '6. How long do we keep your information?',
    description:
    'We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.'
  },
  {
    title: '7. What are your privacy rights?',
    description:
    'You may review, change, or terminate your account at any time.'
  },
  {
    title:
      '8. How can you review, update or delete the data we collect from you?',
    description:
    'You can review, change, or terminate your account at any time.'
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={'h6'}
        gutterBottom
        sx={{
          fontWeight: 'medium',
        }}
      >
        {title}
      </Typography>
      <Typography component={'p'} color={'text.secondary'}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
